import React from 'react';

type LoadingSize = 'sm'|'md'|'lg'
interface LoaderProps {
    classNames: string;
    style?: React.CSSProperties,
    size?: LoadingSize
}

function Loader({classNames, style, size}: LoaderProps) {
    const getSize = (_size: LoadingSize): string =>{
        if(_size){
            return `spinner-border-${_size}`
        }
        return '';
    }
    return (
        <div className={`spinner-border text-primary ${classNames} ${size&&getSize(size)}`} style={{...style}} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

export default Loader