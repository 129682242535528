import {default as client} from './client';
import {ResponseType, RefreshForm, LoginForm} from './interfaces';

const localStorageAccessKey = 'jtb_access_token';
const localStorageRefreshKey = 'jtb_refresh_token';

// Handle response after registration or login
function handleUserResponse(response : ResponseType ) {
  const {meta, data} = response;
  const{status, info, message} = meta;
  if (status !== "200") {
    return Promise.reject({status, message: info, data});
  }

  if (status === undefined || meta === undefined) {
    return Promise.reject({status: 'error', message: 'Something went wrong', data: null})
  }

  if(message === "OK"){
    const accessToken = data['access_token'];
    const refreshToken = data['refresh_token'];

    localStorage.setItem(localStorageAccessKey, accessToken);
    localStorage.setItem(localStorageRefreshKey, refreshToken);

    return Promise.resolve({status, message: info, data});
  }else{
    return Promise.reject({status: 'error', message: 'Something went wrong', data: null})
  }
}

function handleRefresh(response : ResponseType){
    const {meta, data} = response;
    const{status, info, message} = meta;
}


// Log into user's account after verifying credentials
function login(body: LoginForm) {
  return client('api/auth', {
    body, 
    method: 'POST'
  }).then(handleUserResponse)
}

function refresh(body: RefreshForm) {
    return client('api/auth/refresh-token', {
      body, 
      method: 'POST'
    }).then(handleUserResponse)
  }

// Logout user
async function logout() {
  try {
    await localStorage.removeItem('jtb_access_token');
    await localStorage.removeItem('jtb_refresh_token');
    window.location.reload()
    return Promise.resolve(true)
  } catch (error) {
    // Error retrieving data
    console.log(error)
    return Promise.resolve(false)
  }
}

export {
  logout,
  login,
  refresh
}