// const baseURL = "http://40.112.49.213:8080";

interface clientConfig {
  body?: any;
  method?: any;
  headers?:any;
}

async function client(endpoint: string, {body, ...customConfig}:clientConfig = {method: 'GET'}) {
  let headers: any = {
    'content-type': 'application/json',
    'Authorization': `Bearer ${process.env.REACT_APP_NSIRS_API_KEY}`,
    'Access-Control-Allow-Credentials': 'true',
    ...customConfig.headers
  }

  const config =  {
    body,
    ...customConfig,
    headers: headers,
    withCredentials: true
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {...config, credentials: 'include'})
    .then(r => r.json())
    // .catch(e => {
    //   console.log(e)
    // })
}

export default client
